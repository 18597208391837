// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import store from './redux/store'
import { NotificationsProvider } from './context/NotificationsContext'

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyA6Y4uNJumJbVQIGstrP83oTIixdhU74Qs",
  authDomain: "betterfield-3aa2f.firebaseapp.com",
  projectId: "betterfield-3aa2f",
  storageBucket: "betterfield-3aa2f.appspot.com",
  messagingSenderId: "764802208032",
  appId: "1:764802208032:web:0edcc05a0d1e7a04ef8745",
  measurementId: "G-YX48P959XJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
getPerformance(app);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <NotificationsProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </NotificationsProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

