import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { Grid, IconButton, TextField, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// components
import AutocompleteInput from '../../autocomplete-input';
import BaseDialog from '../../base-dialog';
import { editUser, changePassword, requestUpload, upload } from '../../../pages/users/actions';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
import { ReactComponent as DefaultUserIcon } from '../../../assets/ic_default_user.svg';
import { UserIcon, State } from '../../UserIcon';

EditUserDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.object,
    onClose: PropTypes.func,
    onEdited: PropTypes.func,
};

// TODO --> IMPLEMENT EMPLOYEE & PASSWORD
export default function EditUserDialog({
    title,
    open,
    value,
    onClose,
    onEdited,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const session = getUserLocalStorage();
    const { showNotification } = useNotificationsProvider();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isCurrentEmail, setIsCurrentEmail] = useState('');

    const [photo, setPhoto] = useState(null);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [photoState, setPhotoState] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState({ id: value.role, title: value.roleName });
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const resetState = () => {
        setLoading(false);
        setError(null);
        setPhoto(value.photoUrl ? value.photoUrl : null);
        setPhotoUrl(value.photoUrl);
        setPhotoState(null);
        setFirstName(value.firstName);
        setLastName(value.lastName);
        setRole({ id: value.role, title: value.roleName });
        if (isCurrentEmail) {
            setOldPassword('');
            setPassword('');
            setConfirmationPassword('');
        }
    };

    const onCloseDialog = (event, reason) => {
        if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
            onClose();
        } else {
            resetState();
            onClose();
        }
    };

    const onConfirm = () => {
        if (firstName.length === 0) {
            setError('firstName_required');
            return;
        }

        if (lastName.length === 0) {
            setError('lastName_required');
            return;
        }

        if (role.length === 0) {
            setError('role_required');
            return;
        }

        if (isCurrentEmail) {
            if (oldPassword.length !== 0 || password.length !== 0 || confirmationPassword.length !== 0) {
                if (oldPassword.length === 0) {
                    setError('oldPassword_required');
                    return;
                }

                if (password.length === 0) {
                    setError('password_required');
                    return;
                }

                if (confirmationPassword !== password) {
                    setError('not_password');
                    return;
                }
            }
        }

        setLoading(true);

        if (isCurrentEmail && oldPassword.length !== 0 && password.length !== 0 && confirmationPassword.length !== 0) {
            // Change password
            const data = {
                oldPassword,
                newPassword: password
            }

            dispatch(changePassword(value.id, data, () => {
                onEditUser();
            }, () => {
                showNotification('error', t('user.edit-user-dialog.error', { name: value.fullName }));
                setLoading(false);
            }));
        } else {
            onEditUser();
        }
    };

    const onEditUser = () => {
        const data = {
            firstName,
            lastName,
            role: role.id
        };

        if (photoUrl) {
            data.photoUrl = photoUrl;
        }

        const prev = {
            firstName: value.firstName,
            lastName: value.lastName,
            role: value.role,
            photoUrl: value.photoUrl,
        }

        // If the object to be sent is the same as the 
        // one it came in, then it leaves the modal, 
        // clearing the form fields and not executing 
        // an API call.
        if (dataEqual(data, prev)) {
            resetState();
            onEdited();
            return;
        }

        dispatch(editUser(value.id, data, () => {
            resetState();
            showNotification('success', t('user.edit-user-dialog.success', { name: `${firstName} ${lastName}` }));
            onEdited();
        }, () => {
            showNotification('error', t('user.edit-user-dialog.error', { name: value.fullName }));
            setLoading(false);
        }));
    };

    const handleImageUpload = (event) => {
        const image = event.target.files[0];
        if (image) {
            setPhotoState(State.Loading);
            setPhoto(URL.createObjectURL(image));
            // Get S3 url
            const requestUploadData = {
                contentType: image.type,
            };
            console.log('requestUploadData', requestUploadData);
            dispatch(
                requestUpload(requestUploadData, (response) => {
                    // Prepare multiform data
                    const formDataFields = response.formData.fields || {};
                    const formData = new FormData();
                    Object.keys(formDataFields).forEach((fieldName) => {
                        const fieldValue = formDataFields[fieldName];
                        formData.append(fieldName, fieldValue);
                    });
                    formData.append('file', image);
                    // Upload image to S3
                    console.log('response.formData.url', response.formData.url);
                    dispatch(
                        upload(response.formData.url, formData, () => {
                            setPhotoUrl(response.url);
                            setPhotoState(State.Success);
                        }, () => {
                            setPhotoState(State.Error);
                            showNotification('error', t('common.api-error'));
                        })
                    )
                }, () => {
                    setPhotoState(State.Error);
                    showNotification('error', t('common.api-error'));
                })
            );
        }
    };

    // Method that checks if both objects are equal, 
    // comparing the size and values of the fields.
    const dataEqual = (data, prev) => {
        const dataKeys = Object.keys(data);
        const prevKeys = Object.keys(prev);

        if (dataKeys.length !== prevKeys.length) {
            return false;
        }

        return dataKeys.every(key => data[key] === prev[key]);
    };

    useEffect(() => {
        if (!value.email) {
            return;
        }

        // APPLY REFRESH SESSION
        if (session) {
            setIsCurrentEmail(session.user.email === value.email);
        }

        setFirstName(value.firstName);
        setLastName(value.lastName);
        setRole({ id: value.role, title: value.roleName });
        setPhoto(value.photoUrl ? value.photoUrl : null);
        setPhotoUrl(value.photoUrl);
        setPhotoState(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <BaseDialog
            open={open}
            onClose={onCloseDialog}
            onConfirm={onConfirm}
            title={title}
            loading={loading}
        >
            <Grid container direction="column" spacing={4}>
                <Grid item marginTop={2}>
                    <Grid container justifyContent="center" alignItems="center" >
                        {photo ? (
                            <IconButton component="label">
                                <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                                <UserIcon src={photo} width={58} height={58} state={photoState} />
                            </IconButton>
                        ) : (
                            <IconButton component="label">
                                <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                                <DefaultUserIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField
                        error={error === 'firstName_required'}
                        onChange={({ target }) => {
                            setFirstName(target.value);
                        }}
                        defaultValue={firstName && firstName}
                        value={firstName && firstName}
                        label={t('user.edit-user-dialog.firstName-title')}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        error={error === 'lastName_required'}
                        onChange={({ target }) => {
                            setLastName(target.value);
                        }}
                        defaultValue={lastName && lastName}
                        value={lastName && lastName}
                        label={t('user.edit-user-dialog.lastName-title')}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        defaultValue={value && value.email}
                        value={value && value.email}
                        label={t('user.edit-user-dialog.email-title')}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item {...!isCurrentEmail && { marginBottom: 2 }}>
                    <AutocompleteInput
                        error={error === 'role_required'}
                        onChange={(val) => {
                            setRole(val);
                        }}
                        defaultValue={role && role}
                        value={role && role}
                        label={t('user.edit-user-dialog.role-title')}
                        resource="users/roles-autocomplete"
                        required
                        canDelete
                        refreshOnInputChange
                        clearOnBlur={false}
                        freeSolo
                    />
                </Grid>
                {isCurrentEmail && (
                    <>
                        <Grid item>
                            <TextField
                                type={showOldPassword ? 'text' : 'password'}
                                error={error === 'oldPassword_required'}
                                onChange={({ target }) => {
                                    setOldPassword(target.value);
                                }}
                                label={t('user.edit-user-dialog.old-password-title')}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                error={error === 'password_required'}
                                onChange={({ target }) => {
                                    setPassword(target.value);
                                }}
                                label={t('user.edit-user-dialog.password-title')}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item marginBottom={2}>
                            <TextField
                                type={showConfirmPassword ? 'text' : 'password'}
                                error={error === 'not_password'}
                                onChange={({ target }) => {
                                    setConfirmationPassword(target.value);
                                }}
                                label={t('user.edit-user-dialog.confirm-password-title')}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </BaseDialog>
    );
};

function getUserLocalStorage() {
    return JSON.parse(localStorage.getItem('session_current'));
};