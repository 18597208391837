import { Roles } from './permissions'

const DAILY_INSPECTIONS = 0 // Receive a notification with all completed inspections of the previous day
const WEEKLY_INSPECTIONS = 1 // Receive a notification with all completed inspections of the previous week
const COMPLETED_INSPECTIONS = 2 // Receive a notification when an inspection is completed

const notificationTypes = [
  DAILY_INSPECTIONS,
  WEEKLY_INSPECTIONS,
  COMPLETED_INSPECTIONS,
]

// Map roles to notification types
const notificationTypePerRole = {
  'super-admin': [DAILY_INSPECTIONS, WEEKLY_INSPECTIONS, COMPLETED_INSPECTIONS],
  [Roles.ADMIN]: [DAILY_INSPECTIONS, WEEKLY_INSPECTIONS, COMPLETED_INSPECTIONS],
  [Roles.MANAGER]: [DAILY_INSPECTIONS, WEEKLY_INSPECTIONS, COMPLETED_INSPECTIONS],
  [Roles.OPERATOR]: [],
}

const translations = {
  [DAILY_INSPECTIONS]: 'notification-type.daily-inspections',
  [WEEKLY_INSPECTIONS]: 'notification-type.weekly-inspections',
  [COMPLETED_INSPECTIONS]: 'notification-type.completed-inspections',
}

function translate(notificationType, t) {
  return t(translations[notificationType])
}

function hasNotifications(role) {
  return notificationTypePerRole[role].length > 0
}

function getNotificationTypes(role) {
  return notificationTypePerRole[role]
}

function hasNotificationType(role, notificationType) {
  return notificationTypePerRole[role].includes(notificationType)
}

export {
  DAILY_INSPECTIONS,
  WEEKLY_INSPECTIONS,
  COMPLETED_INSPECTIONS,
  notificationTypes,
  translate,
  hasNotifications,
  getNotificationTypes,
  hasNotificationType,
}
