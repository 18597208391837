import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
  AUTH_GET_WORKSPACES_SUCCESS,
  AUTH_GET_WORKSPACES_FAILED,
  AUTH_CHANGE_WORKSPACE_SUCCESS,
  AUTH_CHANGE_WORKSPACE_FAILED,
  AUTH_MANAGE_NOTIFICATIONS_SUCCESS,
  AUTH_MANAGE_NOTIFICATIONS_FAILED
} from './types';

const initialState = {
  current: {
    user: null,
    workspaces: null
  },
};

export default function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          user: action.payload.user
        }
      }
    case AUTH_LOGIN_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case AUTH_GET_WORKSPACES_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          workspaces: action.payload
        }
      };
    case AUTH_GET_WORKSPACES_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case AUTH_CHANGE_WORKSPACE_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          user: action.payload.user
        }
      }
    case AUTH_CHANGE_WORKSPACE_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case AUTH_MANAGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          user: action.payload
        }
      }
    case AUTH_MANAGE_NOTIFICATIONS_FAILED:
      return {
        ...state,
        error: action.payload
      };
    default:
      break;
  }
  return state;
}