export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const REQUEST_UPLOAD_ITEM_IMAGE = 'REQUEST_UPLOAD_ITEM_IMAGE';
export const REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS = 'REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS';
export const REQUEST_UPLOAD_ITEM_IMAGE_FAILED = 'REQUEST_UPLOAD_ITEM_IMAGE_FAILED';
export const UPLOAD_ITEM_IMAGE = 'UPLOAD_ITEM_IMAGE';
export const UPLOAD_ITEM_IMAGE_SUCCESS = 'UPLOAD_ITEM_IMAGE_SUCCESS';
export const UPLOAD_ITEM_IMAGE_FAILED = 'UPLOAD_ITEM_IMAGE_FAILED';
export const MANAGE_NOTIFICATIONS = 'MANAGE_NOTIFICATIONS';
export const MANAGE_NOTIFICATIONS_SUCCESS = 'MANAGE_NOTIFICATIONS_SUCCESS';
export const MANAGE_NOTIFICATIONS_FAILED = 'MANAGE_NOTIFICATIONS_FAILED';