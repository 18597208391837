import React from "react";
// material
import { IconButton } from "@mui/material";
// components
import { UserIcon } from "../../components/UserIcon";
import { ReactComponent as DefaultUserIcon } from '../../assets/ic_default_user.svg';
import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/ic_delete.svg';

export default function generate({ t, onEdit, onDelete, isCurrentUser }) {
    return [
        {
            name: 'photoUrl',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (val) => (
                    val ? <UserIcon src={val} width={32} height={32} /> : <DefaultUserIcon width={32} height={32} />
                ),
            },
        },
        {
            name: 'fullName',
            label: t('user.items.name'),
        },
        {
            name: 'roleName',
            label: t('user.items.role'),
        },
        {
            name: 'modify',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onEdit(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        },
        {
            name: 'delete',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (val, tableMeta) => (
                    (!isCurrentUser(tableMeta.rowIndex)) &&
                    <IconButton
                        onClick={() => onDelete(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
            },
        },
    ];
};