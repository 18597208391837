import * as TYPE from './types';
import { apiAction, uploadAction } from '../../api/actions';

export function getUsers(params) {
    return apiAction({
        resource: '/su/users',
        params,
        method: 'GET',
        onSuccess: (data) => onGetSuccess(data),
        onFailure: (error) => onGetFailed(error),
        carry: {
            label: TYPE.GET_USERS
        },
    });
};

function onGetSuccess(data) {
    return {
        type: TYPE.GET_USERS_SUCCESS,
        payload: data,
    };
};

function onGetFailed(error) {
    return {
        type: TYPE.GET_USERS_FAILED,
        payload: {
            error
        }
    };
};

export function addUser(data, onSuccess, onFailure) {
    return apiAction({
        resource: '/su/users',
        method: 'POST',
        data,
        onSuccess: (data) => onAddSuccess(data, onSuccess),
        onFailure: (error) => onAddFailed(error, onFailure),
        carry: {
            label: TYPE.ADD_USER
        },
    });
};

function onAddSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.ADD_USER_SUCCESS,
        payload: data,
    };
};

function onAddFailed(error, onFailure) {
    onFailure();
    return {
        type: TYPE.ADD_USER_FAILED,
        payload: {
            error
        }
    };
};

export function editUser(id, data, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/users/${id}/edit`,
        method: 'PUT',
        data,
        onSuccess: (data) => onEditSuccess(data, onSuccess),
        onFailure: (error) => onEditFailed(error, onFailure),
        carry: {
            label: TYPE.EDIT_USER
        },
    });
};

function onEditSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.EDIT_USER_SUCCESS,
        payload: data
    }
};

function onEditFailed(error, onFailure) {
    onFailure();
    return {
        type: TYPE.EDIT_USER_FAILED,
        payload: {
            error
        }
    }
};

export function deleteUser(id, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/users/${id}`,
        method: 'DELETE',
        data: {},
        onSuccess: (data) => onDeleteSuccess(data, onSuccess),
        onFailure: (error) => onDeleteFailed(error, onFailure),
        carry: {
            label: TYPE.DELETE_USER
        },
    });
};

function onDeleteSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.DELETE_USER_SUCCESS,
        payload: data
    };
};

function onDeleteFailed(error, onFailure) {
    onFailure(error);
    return {
        type: TYPE.DELETE_USER_FAILED,
        payload: {
            error
        }
    }
};

export function changePassword(id, data, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/users/${id}/change-password`,
        method: 'PUT',
        data,
        onSuccess: (data) => onChangePasswordSuccess(data, onSuccess),
        onFailure: (error) => onChangePasswordFailed(error, onFailure),
        carry: {
            label: TYPE.CHANGE_PASSWORD
        },
    });
};

function onChangePasswordSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.CHANGE_PASSWORD_SUCCESS,
        payload: data
    }
};

function onChangePasswordFailed(error, onFailure) {
    onFailure();
    return {
        type: TYPE.CHANGE_PASSWORD_FAILED,
        payload: {
            error
        }
    }
};

export function requestUpload(requestUploadData, onSuccess, onFailure) {
    return apiAction({
        resource: '/su/upload',
        method: 'POST',
        data: requestUploadData,
        onSuccess: (data) => onRequestUploadSuccess(data, onSuccess),
        onFailure: (error) => onRequestUploadFailed(error, onFailure),
        carry: {
            label: TYPE.REQUEST_UPLOAD_ITEM_IMAGE
        },
    });
}

function onRequestUploadSuccess(data, onSuccess) {
    onSuccess(data)
    return {
        type: TYPE.REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS,
        payload: data
    };
}

function onRequestUploadFailed(error, onFailure) {
    onFailure()
    return {
        type: TYPE.REQUEST_UPLOAD_ITEM_IMAGE_FAILED,
        payload: {
            error
        }
    };
}

export function upload(url, formData, onSuccess, onFailure) {
    return uploadAction({
        url,
        method: 'POST',
        data: formData,
        onSuccess: () => onUploadSuccess(onSuccess),
        onFailure: (error) => onUploadFailed(error, onFailure),
        carry: {
            label: TYPE.UPLOAD_ITEM_IMAGE
        },
    });
}

function onUploadSuccess(onSuccess) {
    onSuccess()
    return {
        type: TYPE.UPLOAD_ITEM_IMAGE_SUCCESS,
        payload: {
            uploaded: true
        }
    };
}

function onUploadFailed(error, onFailure) {
    onFailure()
    return {
        type: TYPE.UPLOAD_ITEM_IMAGE_FAILED,
        payload: {
            error
        }
    };
}

export function manageNotifications(id, data, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/users/${id}/manage-notifications`,
        method: 'PUT',
        data,
        onSuccess: (data) => onManageNotificationsSuccess(data, onSuccess),
        onFailure: (error) => onManageNotificationsFailed(error, onFailure),
        carry: {
            label: TYPE.MANAGE_NOTIFICATIONS
        },
    });
};

function onManageNotificationsSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.MANAGE_NOTIFICATIONS_SUCCESS,
        payload: data
    };
};

function onManageNotificationsFailed(error, onFailure) {
    onFailure(error);
    return {
        type: TYPE.MANAGE_NOTIFICATIONS_FAILED,
        payload: {
            error
        }
    }
};