import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// material 
import { styled } from '@mui/material/styles'
import { Grid, SvgIcon, Typography, Container, IconButton } from '@mui/material';
// component
import Page from '../../../components/Page';
import PageHeader from "../../../components/page-header";
import BFTable from "../../../components/table";
import BFHierarchyTable from '../../../components/hierarchy-table';
import InfoCard from '../../../components/inspections/see-inspection/info-inspection-card';
import EvaluationFormCard from '../../../components/inspections/see-inspection/evaluation-form-card';
import MediaCarouselDialog from '../../../components/carousel-dialog';
import InfoPopover from '../../../components/info-popover';
import generateColumns from "./columns";
import { ReactComponent as DownloadIcon } from '../../../assets/ic_download.svg';
import { ReactComponent as ReturnIcon } from '../../../assets/ic_return.svg';
import { getInspectionItems } from '../actions';
import { selectInspectionItems } from '../selectors';
import { generateInspectionPdf, startInspectionPdfGeneration } from './actions';
import { selectInspectionReport } from './selectors';
import { selectIsFeatureEnabled } from '../../../redux/features/selectors';
import { Features } from '../../../entities/features';

const ContainerStyle = styled(Grid)(({ theme }) => ({
    shadowColor: theme.palette.secondary,
    backgroundColor: theme.palette.grey,
    marginTop: 25,
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

const ReturnButton = {
    marginTop: 'calc(100vh - 120vh)',
};

export default function SeeInspection() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const inspectionItems = useSelector(selectInspectionItems());

    const inspectionReport = useSelector(selectInspectionReport());

    const isResourceEvaluationFormFeatureEnabled = useSelector(selectIsFeatureEnabled(Features.FT_RESOURCE_EVALUATION_FORM));

    const [inspection, setInspection] = useState(null);
    const [media, setMedia] = useState([]);
    const [openMediaCaroucelDialog, setOpenMediaCarouselDialog] = useState(false);

    const [itemsTableData, setItemsTableData] = useState(null);
    const [itemsTableState, setItemsTableState] = useState({
        page: 0,
        sort: null,
        filters: null,
    });

    const resource = `items`;

    const onSee = (value) => {
        if (!openMediaCaroucelDialog) {
            setMedia(value);
            setOpenMediaCarouselDialog(true);
        }
    }

    const onDownloadReport = () => {
        if (inspection?.reportUrl) {
            window.open(inspection.reportUrl, '_blank');
        } else {
            // Instantly generate the report in HTML format.
            dispatch(generateInspectionPdf(inspection.id));

            // Request generation of the report in PDF format.
            dispatch(startInspectionPdfGeneration(inspection.id));
        }
    }

    const itemsTableColumns = generateColumns({
        t,
        onSee,
        controlFlowType: inspection?.flow?.flowType,
    });

    useEffect(() => {
        if (location.state) {
            const { inspection } = location.state;
            setInspection(inspection);
        }
    }, [location]);

    useEffect(() => {
        if (inspection) {
            console.log("Inspection", inspection)
            dispatch(getInspectionItems(
                inspection.id,
                {
                    range: { page: itemsTableState.page + 1 },
                    filters: itemsTableState.filters,
                    sort: itemsTableState.sort,
                }));
        }
    }, [dispatch, itemsTableState, inspection]);

    useEffect(() => {
        console.log("inspectionItems", inspectionItems)
        if (inspectionItems) {
            console.log("Setting items table data")
            setItemsTableData({ items: inspectionItems, itemCount: 2, range: { limit: 15 } });
        }

        if (inspectionItems?.error) {
            console.log("Clearing items table data")
            setItemsTableData({ items: [], itemCount: 0, range: { limit: 15 } });
        }
    }, [inspectionItems]);

    useEffect(() => {
        if (inspectionReport) {
            // Open new tab with the generated report in HTML.
            const newWindow = window.open();
            newWindow.document.write(inspectionReport);
        }
    }, [inspectionReport]);

    return (
        <>
            <MediaCarouselDialog
                files={media}
                titles={{
                    title: t('inspections.carousel.title', { name: inspection?.resource.name }),
                    back: t('inspections.carousel.back'),
                    next: t('inspections.carousel.next'),
                    invalid: t('inspections.carousel.invalid'),
                }}
                open={openMediaCaroucelDialog}
                onClose={() => setOpenMediaCarouselDialog(false)}
            />
            <Page title={t('inspections.see-inspection.tab-title', { name: inspection?.resource.name })}>
                <IconButton
                    onClick={() => navigate('/dashboard/inspections')}
                    style={ReturnButton}
                >
                    <ReturnIcon />
                </IconButton>
                <Container>
                    <PageHeader title={t('inspections.see-inspection.page-title', { name: inspection?.resource.name })} buttonTitle={t('inspections.see-inspection.download')} buttonIcon={(<SvgIcon>
                        {<DownloadIcon />}
                    </SvgIcon>)}
                        onButtonClick={onDownloadReport} />
                    <Typography variant="subtitle1">{t('inspections.see-inspection.page-sub-title', { name: inspection?.flow.name })}</Typography>
                    <ContainerStyle>
                        <Grid container direction="column" spacing={2} marginBottom={5}>
                            <Grid item>
                                <SubTitle variant="overline">{t('inspections.see-inspection.inspection-id')}</SubTitle>
                            </Grid>
                            <Grid item>
                                <InfoCard
                                    t={t}
                                    data={inspection}
                                />
                            </Grid>
                        </Grid>
                        {(isResourceEvaluationFormFeatureEnabled && inspection?.evaluationForm != null) && (
                            <Grid container direction="column" spacing={2} marginBottom={5}>
                                <Grid item>
                                    <SubTitle variant="overline">{t('inspections.see-inspection.inspection-evaluation')}</SubTitle>
                                </Grid>
                                <Grid item>
                                    <EvaluationFormCard
                                        t={t}
                                        data={inspection?.evaluationForm}
                                        onSee={onSee}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid container direction="column" spacing={2}>
                            <Grid container marginTop={2} marginLeft={1} columnSpacing={1} direction="row" alignContent="center" alignItems="center">
                                <Grid item>
                                    <SubTitle variant="overline">{t('inspections.see-inspection.inspection-items')}</SubTitle>
                                </Grid>
                                <Grid item>
                                    <InfoPopover message={t('inspections.see-inspection.inspection-items-info-message')} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                {inspection?.flow?.flowType?.id === 1 ? (
                                    <BFTable
                                        id={resource}
                                        columns={itemsTableColumns}
                                        data={itemsTableData}
                                        page={itemsTableState.page}
                                        sort={itemsTableState.sort}
                                        onChangePage={(currentPage) => {
                                            setItemsTableState({ ...itemsTableState, page: currentPage });
                                        }}
                                        onColumnSortChange={(changedColumn, direction) => {
                                            const newSort = {
                                                field: changedColumn,
                                                direction: direction.toUpperCase(),
                                            };
                                            setItemsTableState({ ...itemsTableState, sort: newSort });
                                        }}
                                    />
                                ) : (
                                    <BFHierarchyTable
                                        id={resource}
                                        columns={itemsTableColumns}
                                        data={itemsTableData}
                                        leaf="children"
                                        page={itemsTableState.page}
                                        sort={itemsTableState.sort}
                                        onChangePage={(currentPage) => {
                                            setItemsTableState({ ...itemsTableState, page: currentPage });
                                        }}
                                        onColumnSortChange={(changedColumn, direction) => {
                                            const newSort = {
                                                field: changedColumn,
                                                direction: direction.toUpperCase(),
                                            };
                                            setItemsTableState({ ...itemsTableState, sort: newSort });
                                        }}
                                    />
                                )
                                }
                            </Grid>
                        </Grid>
                    </ContainerStyle>
                </Container>
            </Page>
        </>
    );
};