import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Container, SvgIcon, Grid } from '@mui/material';
// components
import PageHeader from '../../components/page-header';
import Page from '../../components/Page';
import AddResourceDialog from '../../components/resource/add-resource-dialog'
import EditResourceDialog from '../../components/resource/edit-resource-dialog'
import FiltersCard from '../../components/filters-card'
import BaseDialog from '../../components/base-dialog'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import { getResources, deleteResource } from './actions'
import { selectResources } from './selectors'
import generateColumns from './columns'
import BFTable from '../../components/table'
import { hasPermission } from '../login/selectors';
import { Permissions } from '../../entities/permissions';
import AutocompleteInput from '../../components/autocomplete-input';

const ContainerStyle = styled(Grid)(({ theme }) => ({
  shadowColor: theme.palette.secondary,
  backgroundColor: theme.palette.grey,
  marginTop: 25,
}));

export default function Resource() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { showNotification } = useNotificationsProvider()

  const resources = useSelector(selectResources())

  // Permissions
  const hasAddPermission = useSelector(hasPermission(Permissions.PAGE_RESOURCES_ADD))
  const hasEditPermission = useSelector(hasPermission(Permissions.PAGE_RESOURCES_EDIT))
  const hasRemovePermission = useSelector(hasPermission(Permissions.PAGE_RESOURCES_REMOVE))

  const [openAddResourceDialog, setOpenAddResourceDialog] = useState(false)
  const [openEditResourceDialog, setOpenEditResourceDialog] = useState(false)
  const [openDeleteResourceDialog, setOpenDeleteResourceDialog] = useState(false)
  const [selectedResource, setSelectedResource] = useState({})
  const [loading, setLoading] = useState(false)

  const [itemsTableState, setItemsTableState] = useState({
    page: 0,
    sort: null,
    filters: null
  })
  const [itemsTableData, setItemsTableData] = useState(null)

  const resourceId = `resource`

  // Filters
  const [resource, setResource] = useState('');
  const [resourceType, setResourcesType] = useState('');

  const onCancel = () => {
    setResource('');
    setResourcesType('');
  }

  const onSearch = () => {
    setItemsTableState({ ...itemsTableState, filters: computedFilters });
  };

  const computedFilters = useMemo(() => {
    const filtersObj = {
      id: resource ? resource.id : null,
      resourceTypeId: resourceType ? resourceType.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  }, [resource, resourceType])

  const onEdit = (rowIndex) => {
    if (!openEditResourceDialog) {
      setSelectedResource(itemsTableData.items[rowIndex])
      setOpenEditResourceDialog(true)
    }
  }
  const onSee = (rowIndex) => {
    const resource = itemsTableData.items[rowIndex];
    navigate(`/dashboard/resources/${resource.id}`, { state: { resource } });
  };

  const onDelete = (rowIndex) => {
    if (!openDeleteResourceDialog) {
      setSelectedResource(itemsTableData.items[rowIndex])
      setOpenDeleteResourceDialog(true)
    }
  }

  const itemsTableColumns = generateColumns({
    t,
    onSee,
    onModify: onEdit,
    onDelete,
    hasEditPermission,
    hasRemovePermission
  })

  const onDeleted = () => {
    setLoading(true)

    if (selectedResource) {
      dispatch(deleteResource(selectedResource.id, () => {
        dispatch(getResources({
          range: { page: itemsTableState.page + 1 },
          filters: itemsTableState.filters,
          sort: itemsTableState.sort,
        }));
        setLoading(false)
        setOpenDeleteResourceDialog(false)
        showNotification('success', t('resource.delete-resource-dialog.success', { name: selectedResource.name }))
      }, () => {
        showNotification('error', t('common.api-error'))
        setLoading(false)
        setOpenDeleteResourceDialog(false)
      }))
    } else {
      showNotification('error', t('common.api-error'))
      setLoading(false)
      setOpenDeleteResourceDialog(false)
    }
  }

  const onAdd = () => {
    if (!openAddResourceDialog) {
      setOpenAddResourceDialog(true)
    }
  }

  useEffect(() => {
    dispatch(getResources({
      range: { page: itemsTableState.page + 1 },
      filters: itemsTableState.filters,
      sort: itemsTableState.sort,
    }));
  }, [dispatch, itemsTableState]);

  useEffect(() => {
    if (resources) {
      setItemsTableData(resources)
    }
  }, [resources]);


  return (
    <>
      <BaseDialog
        open={openDeleteResourceDialog}
        onClose={() => setOpenDeleteResourceDialog(false)}
        onConfirm={onDeleted}
        title={t('resource.delete-resource-dialog.title', { name: selectedResource.name })}
        loading={loading}
      />
      <AddResourceDialog
        title={t('resource.add-resource-dialog.title')}
        open={openAddResourceDialog}
        onClose={() => setOpenAddResourceDialog(false)}
        onCreated={() => {
          dispatch(getResources({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
          }));
          setOpenAddResourceDialog(false)
        }}
      />
      <EditResourceDialog
        title={t('resource.edit-resource-dialog.title')}
        value={selectedResource}
        open={openEditResourceDialog}
        onClose={() => setOpenEditResourceDialog(false)}
        onEdited={() => {
          dispatch(getResources({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
          }));
          setOpenEditResourceDialog(false)
        }}
      />
      <Page title={t('resource.tab-title')}>
        <Container>
          <PageHeader title={t('resource.page-title')} buttonTitle={hasAddPermission ? t('resource.add-resource') : null} buttonIcon={hasAddPermission ? (<SvgIcon>
            {<AddIcon />}
          </SvgIcon>) : null} onButtonClick={onAdd} infoMessage={t('resource.info-message')} />
          <Grid container direction="column" marginTop={3} marginBottom={-3}>
            <Grid item>
              <FiltersCard onSearch={onSearch} onCancel={onCancel} children={
                <Grid item container direction="row" justifyContent="flex-start">
                  <Grid item width="30%" marginRight={4}>
                    <AutocompleteInput
                      onChange={setResource}
                      label={t('inspections.filter.resource')}
                      resource="resource/name-autocomplete"
                      value={resource}
                      defaultValue={resource}
                      refreshOnInputChange
                      clearOnBlur={false}
                      canDelete
                      freeSolo
                    />
                  </Grid>
                  <Grid item width="30%" marginRight={4}>
                    <AutocompleteInput
                      onChange={setResourcesType}
                      label={t('inspections.filter.resource-type')}
                      resource="resource-type/autocomplete"
                      value={resourceType}
                      defaultValue={resourceType}
                      refreshOnInputChange
                      clearOnBlur={false}
                      canDelete
                    />
                  </Grid>
                </Grid>
              } />
            </Grid>
          </Grid>
          <ContainerStyle>
            <BFTable
              id={resourceId}
              columns={itemsTableColumns}
              data={itemsTableData}
              page={itemsTableState.page}
              sort={itemsTableState.sort}
              onChangePage={(currentPage) => {
                setItemsTableState({ ...itemsTableState, page: currentPage })
              }}
              onColumnSortChange={(changedColumn, direction) => {
                const newSort = {
                  field: changedColumn,
                  direction: direction.toUpperCase(),
                }
                setItemsTableState({ ...itemsTableState, sort: newSort })
              }}
            />
          </ContainerStyle>
        </Container>
      </Page>
    </>
  );
}