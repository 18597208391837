import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Button, Grid } from '@mui/material';
import InfoPopover from '../info-popover';

const ButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  justifyContent: 'space-between',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1, 3),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

PageHeader.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonIcon: PropTypes.any,
  onButtonClick: PropTypes.func,
  infoMessage: PropTypes.string,
};

export default function PageHeader({ title, buttonTitle, buttonIcon, onButtonClick, infoMessage }) {
  return (
    <Box>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container direction="row" alignItems="center" spacing={1} >
            <Grid item>
              <Typography variant="h3">
                {title}
              </Typography>
            </Grid>

            {infoMessage && (
              <Grid item>
                <InfoPopover message={infoMessage} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {(buttonTitle || buttonIcon) && (
          <Grid item>
            <ButtonStyle
              startIcon={buttonIcon}
              onClick={onButtonClick}>
              {buttonTitle}
            </ButtonStyle>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}