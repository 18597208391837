import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import BaseDialog from '../../base-dialog'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import {
  getNotificationTypes,
  translate,
} from '../../../entities/notification-type'

import { manageNotifications } from '../../../pages/users/actions'

ManageUserNotificationsDialog.propTypes = {
  user: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default function ManageUserNotificationsDialog({
  user,
  open,
  onClose,
  onConfirm,
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState()

  const { showNotification } = useNotificationsProvider()

  const [notifications, setNotifications] = useState(user.notifications || [])

  const dispatch = useDispatch();

  const clearData = () => {
    setLoading(false)
    setNotifications(user.notifications || [])
  }
  const onConfirmNotifications = () => {
    const data = {
      notifications,
    }

    console.log('NOTIFICATIONS DATA:', data)

    setLoading(true)
    dispatch(manageNotifications('me', data, () => {
      onConfirm()
      setLoading(false)
      clearData()
      showNotification('success', t('user.manage-notifications-dialog.success'))
    }, (err) => {
      setLoading(false)
      if (
        err.response.data.validationErrors &&
        err.response.data.validationErrors.length > 0
      ) {
        showNotification(
          'error',
          err.response.data.validationErrors[0].errorMessage
        )
      } else {
        showNotification('error', t('errors.api-error'))
      }
    }))
  }

  useEffect(() => {
    setNotifications(user.notifications || [])
  }, [user])

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        clearData()
        onClose()
      }}
      onConfirm={onConfirmNotifications}
      title={t('user.manage-notifications-dialog.title')}
      infoMessage={t('user.manage-notifications-dialog.info-message')}
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="body2">
            {t('user.manage-notifications-dialog.message')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            {getNotificationTypes(user.role)?.map((notificationType) => (
              <FormControlLabel
                key={notificationType}
                control={
                  <Checkbox
                    key={notificationType}
                    checked={notifications.includes(notificationType)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setNotifications([...notifications, notificationType])
                      } else {
                        setNotifications(
                          notifications.filter((n) => n !== notificationType)
                        )
                      }
                    }}
                  />
                }
                label={translate(notificationType, t)}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
