import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Container, Grid, SvgIcon, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// components
import { getFlows, deleteFlow } from './actions';
import { selectFlows } from './selectors';
import generateColumns from './columns';
import Page from '../../components/Page';
import PageHeader from '../../components/page-header';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import BFTable from '../../components/table';
import BaseDialog from '../../components/base-dialog';
import AddFlowDialog from '../../components/flows/add-flow-dialog';
import EditFlowDialog from '../../components/flows/edit-flow-dialog';
import SeeFlowDialog from '../../components/flows/see-flow-dialog';
import { FlowTypes } from '../../entities/flow-type'
import { useNotificationsProvider } from '../../context/NotificationsContext';
import { hasPermission } from '../login/selectors';
import { Permissions } from '../../entities/permissions';
import AutocompleteInput from '../../components/autocomplete-input';
import FiltersCard from '../../components/filters-card';

const ContainerStyle = styled(Grid)(({ theme }) => ({
    shadowColor: theme.palette.secondary,
    backgroundColor: theme.palette.grey,
    marginTop: 25,
}));

export default function Flows() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { showNotification } = useNotificationsProvider();

    const flows = useSelector(selectFlows());

    // Permissions
    const hasAddPermission = useSelector(hasPermission(Permissions.PAGE_FLOWS_ADD))
    const hasEditPermission = useSelector(hasPermission(Permissions.PAGE_FLOWS_EDIT))
    const hasRemovePermission = useSelector(hasPermission(Permissions.PAGE_FLOWS_REMOVE))

    const [openAddFlowDialog, setOpenAddFlowDialog] = useState(false);
    const [openSeeFlowDialog, setOpenSeeFlowDialog] = useState(false);
    const [openEditFlowDialog, setOpenEditFlowDialog] = useState(false);
    const [openDeleteFlowDialog, setOpenDeleteFlowDialog] = useState(false);

    const [selectedFlow, setSelectedFlow] = useState(null);
    const [selectedFlows, setSelectedFlows] = useState([]);
    const [loading, setLoading] = useState(false);

    const [itemsTableState, setItemsTableState] = useState({
        page: 0,
        sort: null,
        filters: null
    });

    const [itemsTableData, setItemsTableData] = useState(null);

    const resource = `flows`;

    // Filters
    const [flow, setFlow] = useState('');
    const [flowType, setFlowType] = useState('');

    const onCancel = () => {
        setFlow('');
        setFlowType('');
    }

    const onSearch = () => {
        setItemsTableState({ ...itemsTableState, filters: computedFilters });
    };

    const computedFilters = useMemo(() => {
        const filtersObj = {
            id: flow ? flow.id : null,
            flowTypeId: flowType || null
        }
        // remove nulls
        Object.keys(filtersObj).forEach(
            (key) => filtersObj[key] === null && delete filtersObj[key]
        )
        return filtersObj
    }, [flow, flowType])

    const onAdd = () => {
        if (!openAddFlowDialog) {
            setOpenAddFlowDialog(true);
        }
    };

    const onOpen = (rowIndex) => {
        if (!openSeeFlowDialog) {
            setSelectedFlow(itemsTableData.items[rowIndex]);
            setOpenSeeFlowDialog(true)
        }
    };

    const onEdit = (rowIndex) => {
        if (!openEditFlowDialog) {
            setSelectedFlow(itemsTableData.items[rowIndex]);
            setOpenEditFlowDialog(true)
        }
    };

    const onDelete = (rowIndex) => {
        if (!openDeleteFlowDialog) {
            setSelectedFlow(itemsTableData.items[rowIndex]);
            setOpenDeleteFlowDialog(true)
        }
    };

    const onDeleted = () => {
        setLoading(true)

        if (selectedFlow) {
            const data = {}
            dispatch(deleteFlow(selectedFlow.id, data, () => {
                dispatch(getFlows({
                    range: { page: itemsTableState.page + 1 },
                    filters: itemsTableState.filters,
                    sort: itemsTableState.sort,
                }));
                setLoading(false)
                setOpenDeleteFlowDialog(false)
                showNotification('success', t('flows.delete-flow-dialog.success', { name: selectedFlow.name }))
                setSelectedFlow(null)
            }, () => {
                showNotification('error', t('common.api-error'))
                setLoading(false)
                setOpenDeleteFlowDialog(false)
            }))
        } else {
            showNotification('error', t('common.api-error'))
            setLoading(false)
            setOpenDeleteFlowDialog(false)
        }
    };

    // Method to manage the selected flow rows.
    const onChangeSelectedFlows = (rowIndexs) => {
        if (rowIndexs.length > 1) {
            setSelectedFlows(rowIndexs.map(item => item.index));
        } else if (rowIndexs.length === 1) {
            const row = rowIndexs[0].index;
            if (selectedFlows.includes(row)) {
                setSelectedFlows(selectedFlows.filter((selectedRow) => selectedRow !== row));
            } else {
                setSelectedFlows([...selectedFlows, row]);
            }
        } else {
            setSelectedFlows([]);
        }
    };

    const itemsTableColumns = generateColumns({
        t,
        onOpen,
        onEdit,
        onDelete,
        hasEditPermission,
        hasRemovePermission,
    });

    useEffect(() => {
        dispatch(getFlows({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
        }));
    }, [dispatch, itemsTableState]);

    useEffect(() => {
        if (flows && flows.items) {
            const normalizedFlows = flows.items.map((flow) => {
                const { flowType } = flow;
                if (flowType.name === FlowTypes.FREE) {
                    flowType.translatedName = t('flows.add-flow-dialog.select-type.free')
                } if (flowType.name === FlowTypes.SEQUENTIAL) {
                    flowType.translatedName = t('flows.add-flow-dialog.select-type.sequencial')
                }
                flow.flowType = flowType
                return flow
            })
            flows.items = normalizedFlows
            setItemsTableData(flows);
        }
    }, [flows, t]);

    return (
        <>
            <BaseDialog
                open={openDeleteFlowDialog}
                onClose={() => setOpenDeleteFlowDialog(false)}
                onConfirm={onDeleted}
                title={t('flows.delete-flow-dialog.title', { name: selectedFlow && selectedFlow.name })}
                loading={loading}
            />
            <AddFlowDialog
                title={t('flows.add-flow-dialog.title')}
                open={openAddFlowDialog}
                onClose={() => setOpenAddFlowDialog(false)}
                onCreated={() => {
                    dispatch(getFlows({
                        range: { page: itemsTableState.page + 1 },
                        filters: itemsTableState.filters,
                        sort: itemsTableState.sort,
                    }));
                    setOpenAddFlowDialog(false);
                }}
            />
            <SeeFlowDialog
                title={t('flows.add-flow-dialog.title')}
                open={openSeeFlowDialog}
                flow={selectedFlow}
                onClose={() => setOpenSeeFlowDialog(false)}
                onEdited={() => {
                    dispatch(getFlows({
                        range: { page: itemsTableState.page + 1 },
                        filters: itemsTableState.filters,
                        sort: itemsTableState.sort,
                    }));
                    setOpenSeeFlowDialog(false);
                }}
            />
            <EditFlowDialog
                title={t('flows.edit-flow-dialog.title')}
                flow={selectedFlow}
                open={openEditFlowDialog}
                onClose={() => setOpenEditFlowDialog(false)}
                onCreated={() => {
                    dispatch(getFlows({
                        range: { page: itemsTableState.page + 1 },
                        filters: itemsTableState.filters,
                        sort: itemsTableState.sort,
                    }));
                    setOpenEditFlowDialog(false);
                }}
            />
            <Page title={t('flows.tab-title')}>
                <Container>
                    <PageHeader title={t('flows.page-title')} buttonTitle={hasAddPermission ? t('flows.add-flow') : null} buttonIcon={hasAddPermission ? (<SvgIcon>
                        {<AddIcon />}
                    </SvgIcon>) : null} onButtonClick={onAdd} infoMessage={t('flows.info-message')} />
                    <Grid container direction="column" marginTop={3} marginBottom={-3}>
                        <Grid item>
                            <FiltersCard onSearch={onSearch} onCancel={onCancel} children={
                                <Grid item container direction="row" justifyContent="flex-start">
                                    <Grid item width="30%" marginRight={4}>
                                        <AutocompleteInput
                                            onChange={setFlow}
                                            label={t('flows.filter.flow')}
                                            resource="flows/autocomplete"
                                            value={flow}
                                            defaultValue={flow}
                                            refreshOnInputChange
                                            clearOnBlur={false}
                                            canDelete
                                            freeSolo
                                        />
                                    </Grid>
                                    <Grid item width="30%" marginRight={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="flow-type">{t('flows.filter.flow-type')}</InputLabel>
                                            <Select
                                                labelId="flow-type"
                                                id="type-selector"
                                                value={flowType}
                                                onChange={(event) => setFlowType(event.target.value)}
                                                label={t('flows.filter.flow-type')}
                                            >
                                                <MenuItem value="2">{t('flows.add-flow-dialog.select-type.free')}</MenuItem>
                                                <MenuItem value="1">{t('flows.add-flow-dialog.select-type.sequencial')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            } />
                        </Grid>
                    </Grid>
                    <ContainerStyle>
                        <BFTable
                            id={resource}
                            columns={itemsTableColumns}
                            data={itemsTableData}
                            page={itemsTableState.page}
                            sort={itemsTableState.sort}
                            onChangePage={(currentPage) => {
                                setItemsTableState({ ...itemsTableState, page: currentPage });
                            }}
                            onColumnSortChange={(changedColumn, direction) => {
                                const newSort = {
                                    field: changedColumn,
                                    direction: direction.toUpperCase(),
                                };
                                setItemsTableState({ ...itemsTableState, sort: newSort });
                            }}
                            rowsSelected={selectedFlows}
                            onRowSelectionChange={(rows) => onChangeSelectedFlows(rows)}
                        />
                    </ContainerStyle>
                </Container>
            </Page>
        </>
    );
};