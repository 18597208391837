import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Typography, IconButton, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

InfoPopover.propTypes = {
  message: PropTypes.string,
};

export default function InfoPopover({ message }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    console.log('handlePopoverOpen:', event);
    setAnchorEl(event.currentTarget || event.target)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Grid container>
      <Grid item>
        <IconButton
          aria-owns={isOpen ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
          size="small"
        >
          <InfoIcon fontSize="small" />
        </IconButton>
        <Popover
          id="mouse-over-popover"
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableEnforceFocus
          disableRestoreFocus
          disableAutoFocus
        >
          <Typography variant='body2' sx={{ padding: 1, maxWidth: 300 }}>{message}</Typography>
        </Popover>
      </Grid>
    </Grid>
  );
};