export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_GET_WORKSPACES = 'AUTH_GET_WORKSPACES';
export const AUTH_GET_WORKSPACES_SUCCESS = 'AUTH_GET_WORKSPACES_SUCCESS';
export const AUTH_GET_WORKSPACES_FAILED = 'AUTH_GET_WORKSPACES_FAILED';
export const AUTH_CHANGE_WORKSPACE = 'AUTH_CHANGE_WORKSPACE';
export const AUTH_CHANGE_WORKSPACE_SUCCESS = 'AUTH_CHANGE_WORKSPACE_SUCCESS';
export const AUTH_CHANGE_WORKSPACE_FAILED = 'AUTH_CHANGE_WORKSPACE_FAILED';
export const AUTH_MANAGE_NOTIFICATIONS = 'AUTH_MANAGE_NOTIFICATIONS';
export const AUTH_MANAGE_NOTIFICATIONS_SUCCESS = 'AUTH_MANAGE_NOTIFICATIONS_SUCCESS';
export const AUTH_MANAGE_NOTIFICATIONS_FAILED = 'AUTH_MANAGE_NOTIFICATIONS_FAILED';